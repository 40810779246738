import React, { useContext } from "react";

import {
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

//import MenuIcon from "@material-ui/icons/Menu";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

import UserContext from "../../Providers/UserContext";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar({ title = "Apollo Sci Stack" }) {
  const classes = useStyles();
  const { setAuthUser } = useContext(UserContext);
  return (
    <AppBar position={"sticky"}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <IconButton
          edge={"end"}
          onClick={() => setAuthUser(null)}
          color={"secondary"}
        >
          <PowerSettingsNewIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

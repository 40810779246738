import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert variant="filled" {...props} />;
}

export default function Toast({ error, setError, severity = "warning" }) {
  return (
    <Snackbar
      open={Boolean(error)}
      autoHideDuration={6000}
      onClose={() => setError(null)}
    >
      <Alert onClose={() => setError(null)} severity={severity}>
        {error}
      </Alert>
    </Snackbar>
  );
}

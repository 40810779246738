import React from "react";
import { Box, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

export default function ErrorView({ updateError, timedOut }) {
  return (
    <>
      {(updateError || timedOut) && (
        <Box
          p={3}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <WarningIcon color={"primary"} fontSize={"large"} />
          <Typography color={"primary"} variant={"h6"}>{`${
            updateError
              ? updateError.message
                ? updateError.message
                : "Network error"
              : "The network request timed out"
          }`}</Typography>
          <Typography color={"textSecondary"}>
            Please Try Again Later
          </Typography>
        </Box>
      )}
    </>
  );
}

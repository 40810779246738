import { useReducer, useRef, useCallback, useState } from "react";
import axios from "axios";

export const apiBasePath = "/api";

const initialState = {
  updating: false,
  updated: false,
  updateError: null,
  timedOut: false,
  fetchData: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "updating":
      return { ...initialState, updating: true };
    case "updated":
      return {
        ...initialState,
        updated: true,
        fetchData: action.response.data,
      };
    case "set-error":
      return { ...initialState, updateError: action.error };
    case "timed-out":
      return { ...initialState, timedOut: true };
    case "change":
      return { ...state, updateError: null, fetchData: action.payload };
    default:
      return state;
  }
};

export const useGetRequest = ({ timeout = 30000 }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const timeoutRef = useRef(null);

  const manualUpdate = (payload) => {
    dispatch({ type: "change", payload: payload });
  };

  const sendRequest = useCallback(
    async (path) => {
      try {
        dispatch({ type: "updating" });
        if (!path) {
          throw new Error("endpoint not defined");
        }
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          dispatch({ type: "timed-out" });
        }, timeout);
        const url = `${apiBasePath}${path}`;
        const response = await axios.get(url);
        clearTimeout(timeoutRef.current);
        dispatch({ type: "updated", response });
      } catch (error) {
        clearTimeout(timeoutRef.current);
        dispatch({ type: "set-error", error });
      }
    },
    [timeout]
  );

  return { ...state, sendRequest, manualUpdate };
};

export const useSendRequest = () => {
  const [sending, setSending] = useState(false);

  const sendPostRequest = async (endpoint, data, callback) => {
    setSending(true);
    try {
      let request = await axios.post(`${apiBasePath}${endpoint}`, data, {
        headers: {
          authorization: "",
        },
      });
      setSending(false);
      if (request.data) callback(null, request.data);
      else callback({ message: "Unable to Update Server" });
    } catch (e) {
      setSending(false);
      console.log(e);
      callback(e);
    }
  };

  const sendPutRequest = async (endpoint, data, callback) => {
    setSending(true);
    try {
      let request = await axios.put(`${apiBasePath}${endpoint}`, data, {
        headers: {
          authorization: "",
        },
      });
      setSending(false);
      if (request.data) callback(null, request.data);
      else callback({ message: "Unable to Update Server" });
    } catch (e) {
      setSending(false);
      console.log(e);
      callback(e);
    }
  };

  return { sendPutRequest, sendPostRequest, sending };
};

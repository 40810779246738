import React, { useState, createContext } from "react";

const UserContext = createContext();

export const UserProvider = (props) => {
  const [authUser, setAuthUser] = useState(false);

  const contVal = { authUser, setAuthUser };

  return (
    <UserContext.Provider value={contVal}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;

import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ControledDialogForm({
  show,
  setShow,
  exitFxn,
  submitRequest,
  ExtraButton,
  dialogTitle,
  saveReq,
  ...props
}) {
  const classes = useStyles();

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      onExited={exitFxn}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <form id="user-form" onSubmit={submitRequest}>
          {props.children}
        </form>
      </DialogContent>
      <DialogActions>
        {ExtraButton ? (
          ExtraButton
        ) : (
          <Button onClick={handleClose}>Cancel</Button>
        )}
        <div className={classes.wrapper}>
          <Button
            type="submit"
            form="user-form"
            disabled={Boolean(saveReq)}
            color="primary"
          >
            Save
          </Button>
          {saveReq && (
            <CircularProgress
              thickness={6}
              size={24}
              className={classes.buttonProgress}
            />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}

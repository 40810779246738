import React, { useEffect } from "react";
import { useGetRequest } from "../Utilities/API";
import ErrorView from "../Components/Status/ErrorView";
import Loading from "../Components/Status/Loading";
import {
  Fade,
  Box,
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

function Item({ item, setChosenClient }) {
  return (
    <ListItem divider button onClick={() => setChosenClient(item.id)}>
      <ListItemText primary={item.name} />
    </ListItem>
  );
}

export default function ClientSelect({ setChosenClient }) {
  const {
    updating,
    updateError,
    timedOut,
    sendRequest,
    fetchData: clientList,
  } = useGetRequest({ timeout: 30000 });

  //called on load to fetch item list
  useEffect(() => {
    sendRequest(`/clients/clientlist`);
  }, [sendRequest]);

  const errorProps = { updateError, timedOut };

  return (
    <>
      {(updateError || timedOut) && <ErrorView {...errorProps} />}
      {updating && <Loading />}
      {clientList && (
        <Fade in={true}>
          <Box p={2}>
            <Container disableGutters maxWidth={"sm"} component={Paper}>
              <List>
                <ListItem divider>
                  <ListItemText
                    primary={"Select a Client:"}
                    primaryTypographyProps={{ color: "primary", variant: "h6" }}
                  />
                </ListItem>
                {clientList.map((item, i) => (
                  <Item key={i} item={item} setChosenClient={setChosenClient} />
                ))}
              </List>
            </Container>
          </Box>
        </Fade>
      )}
    </>
  );
}

import React, { useState } from "react";

import { TextField, MenuItem, FormControl } from "@material-ui/core";

export const filterTypes = {
  text: (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
        : true;
    });
  },
};

export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  return (
    <TextField
      variant={"outlined"}
      size={"small"}
      fullWidth
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export const defaultColumn = {
  Filter: DefaultColumnFilter,
};

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  /**
   *
   *
   */

  // Render a multi-select box
  return (
    <TextField
      select
      size={"small"}
      fullWidth
      variant={"outlined"}
      value={filterValue || ""}
      placeholder={"All"}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <MenuItem value="">{"All"}</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}

export function MultiSelectColumnFilter({
  column: { setFilter, preFilteredRows, id },
}) {
  const [selectedItems, setSelectedItems] = useState([]);

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    let retVals = [...options.values()];
    retVals.sort();
    return retVals;
  }, [id, preFilteredRows]);

  const handleChange = (event) => {
    setSelectedItems(event.target.value);
    setFilter(event.target.value);
  };

  return (
    <FormControl>
      <TextField
        fullWidth
        size={"small"}
        select
        SelectProps={{
          multiple: true,
          value: selectedItems,
          onChange: handleChange,
          margin: "dense",
        }}
        variant={"outlined"}
      >
        {options.map((option, i) => (
          <MenuItem value={option} key={i}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
}

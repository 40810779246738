import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./Styles/theme";
import "fontsource-roboto";
import CssBaseline from "@material-ui/core/CssBaseline";
import Home from "./Views/Home";
import { UserProvider } from "./Providers/UserContext";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

function App() {
  //
  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <Home />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </UserProvider>
  );
}

export default App;

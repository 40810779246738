import React, { useEffect, useState } from "react";
import { useGetRequest, useSendRequest } from "./API";
import ErrorView from "../Components/Status/ErrorView";
import Loading from "../Components/Status/Loading";

export default function ListController({
  fetchEndpoint,
  updateEndpoint,
  createEndpoint,
  TableView,
  DialogView,
}) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const { sending, sendPostRequest, sendPutRequest } = useSendRequest();

  const {
    manualUpdate,
    updating,
    updateError,
    timedOut,
    sendRequest,
    fetchData: itemList,
  } = useGetRequest({ timeout: 30000 });

  //called on load to fetch item list
  useEffect(() => {
    sendRequest(`${fetchEndpoint}`);
  }, [sendRequest, fetchEndpoint]);

  /*
  useEffect(() => {
    console.log(itemList);
  }, [itemList]);*/

  //update list with item (either update existing item or add new to list)
  const updateItemInList = (newItem) => {
    let existsInList = false;
    let newList = itemList.map((item) => {
      if (item.id !== newItem.id) return { ...item };
      existsInList = true;
      return { ...item, ...newItem };
    });
    if (!existsInList) newList.unshift(newItem);
    ///update state
    manualUpdate(newList);
  };

  //called to update a new item
  const updateItemRequest = (id, itemData) => {
    sendPutRequest(`${updateEndpoint}/${id}`, itemData, (e, data) => {
      if (e) return console.log(e);
      updateItemInList(data);
      setShowDialog(false);
    });
  };

  const createItemRequest = (itemData) => {
    sendPostRequest(`${createEndpoint}`, itemData, (e, data) => {
      if (e) return console.log(e);
      updateItemInList(data);
      setShowDialog(false);
    });
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowDialog(true);
  };

  const errorProps = { updateError, timedOut };
  const dialogProps = {
    showDialog,
    setShowDialog,
    createItemRequest,
    updateItemRequest,
    selectedItem,
    setSelectedItem,
    sending,
  };
  const tableViewProps = {
    itemList,
    handleItemClick,
  };

  return (
    <>
      {(updateError || timedOut) && <ErrorView {...errorProps} />}
      {updating && <Loading />}
      {itemList && (
        <>
          <TableView {...tableViewProps} />
          <DialogView {...dialogProps} />
        </>
      )}
    </>
  );
}

import React from "react";

import ListController from "../Utilities/ListController";
import UserDialog from "./Dialogs/UserDialog";

import {
  Fade,
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

function Item({ item, handleItemClick }) {
  return (
    <ListItem divider button onClick={() => handleItemClick(item)}>
      <ListItemText
        primary={`${item.name} - ${
          item.client ? item.client.name : "DEVELOPER"
        }`}
        secondary={item.email}
      />
    </ListItem>
  );
}

function UserList({ itemList, handleItemClick }) {
  return (
    <Fade in={true}>
      <Container disableGutters maxWidth={"sm"} component={Paper}>
        <List>
          <ListItem divider>
            <ListItemText
              primary={"Users:"}
              primaryTypographyProps={{ color: "primary", variant: "h6" }}
            />
            <ListItemSecondaryAction>
              <IconButton color={"secondary"} onClick={() => handleItemClick()}>
                <AddCircleIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          {itemList.map((item, i) => (
            <Item key={i} item={item} handleItemClick={handleItemClick} />
          ))}
        </List>
      </Container>
    </Fade>
  );
}

export default function Users() {
  const listProps = {
    fetchEndpoint: "/users/getusers",
    updateEndpoint: "/users/updateuser",
    createEndpoint: "/users/createuser",
    TableView: UserList,
    DialogView: UserDialog,
  };

  return <ListController {...listProps} />;
}

import React, { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from "@material-ui/core";

export default function ItemDialog({
  selectedItem,
  setSelectedItem,
  updateItemRequest,
  createItemRequest,
  showDialog,
  setShowDialog,
  sending,
}) {
  const [formData, setFormData] = useState({});

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    let newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (selectedItem) return updateItemRequest(selectedItem.id, formData);
    createItemRequest(formData);
  };

  return (
    <Dialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
      onExited={() => setSelectedItem(null)}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogContent>
        <form id={"item-form"} onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={"Client Name"}
                name={"name"}
                defaultValue={selectedItem ? selectedItem.name : ""}
                onChange={handleFormChange}
                fullWidth
                variant={"filled"}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={sending} onClick={() => setShowDialog(false)}>
          Cancel
        </Button>
        <Button
          color={"secondary"}
          disabled={sending}
          type={"submit"}
          form={"item-form"}
          variant={"contained"}
        >
          {sending ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

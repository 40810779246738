import React from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";

export default function Loading() {
  return (
    <Box
      p={3}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Typography variant={"h2"} color={"textSecondary"}>
        <CircularProgress color={"inherit"} size={50} />
      </Typography>
      <Typography color={"textSecondary"} variant={"h6"}>
        Loading Data...
      </Typography>
    </Box>
  );
}

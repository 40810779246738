import React, { useState, useContext } from "react";
import { makeStyles, Button } from "@material-ui/core";
import Navbar from "../Components/Navigation/Navbar";
import Companies from "../Components/Companies";
import Users from "../Components/Users";
import Features from "../Components/Features";
//import Versions from "../Components/Versions";
import UserContext from "../Providers/UserContext";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const moduleList = [
  { name: "Companies", module: Companies },
  { name: "Users", module: Users },
  { name: "Features", module: Features },
];

export default function Home() {
  const { authUser } = useContext(UserContext);
  const [activeFeature, setActiveFeature] = useState(moduleList[2]);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Navbar title={activeFeature ? activeFeature.name : "Home"} />
      {authUser.role >= 30 && (
        <div>
          {moduleList.map((m, i) => (
            <Button onClick={() => setActiveFeature(m)} key={i}>
              {m.name}
            </Button>
          ))}
        </div>
      )}
      <div>{activeFeature ? <activeFeature.module /> : <></>}</div>
    </div>
  );
}

import React, { useContext } from "react";
import UserContext from "../Providers/UserContext";

import Main from "./Main";
import Login from "./Login";

function App() {
  const { authUser } = useContext(UserContext);
  return <>{authUser ? <Main /> : <Login />}</>;
}

export default App;

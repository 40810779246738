import React from 'react'
import {DefaultColumnFilter} from './filters'
import {useTable,useSortBy,useFilters,usePagination} from 'react-table'

import { withStyles } from '@material-ui/core/styles';
import {Table,TableRow,TableHead,TableCell,TableBody,TableContainer,TablePagination} from '@material-ui/core' 

const StyledTableCell = withStyles((theme) => ({
  head: {
      backgroundColor: 'rgb(62,74,75)',
      color: theme.palette.common.white,
      fontWeight: 'bold'
  },
      body: {
          fontSize: 16,
      },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function TableSwitcher({rows,visibleColumns,setPageSize,gotoPage,pageIndex,pageSize}){
  
  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
  };

  const handleSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
  }
  
  return(
      <TablePagination colSpan={visibleColumns.length} page={pageIndex} onChangeRowsPerPage={handleSizeChange} onChangePage={handleChangePage} count={rows.length} rowsPerPage={pageSize}/>
    )
}

function AdminTable({columns,data}) {

    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      visibleColumns,
      gotoPage,
      setPageSize,
      state:{pageIndex,pageSize}
    } = useTable({
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      autoResetFilters:false,
      autoResetSortBy:false,
      initialState:{pageSize:25,pageIndex:0}
    },useFilters,useSortBy,usePagination);

    const fwdProps = {rows,visibleColumns,setPageSize,gotoPage,pageIndex,pageSize}

    return (
      <TableContainer>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup,x) => (
            <React.Fragment key={x}>
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                    <StyledTableCell align={'center'} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span className="text-primary">{column.isSorted? column.isSortedDesc? <>&nbsp;&#x25bc;</>:<>&nbsp;&#x25B2;</>: ''}</span>
                    </StyledTableCell>
                ))}
              </TableRow>
              {((headerGroups.length > 1 && x>0) || (headerGroups.length === 1)) &&
                <TableRow {...headerGroup.getHeaderGroupProps()} key={x+'filter'}>
                  {headerGroup.headers.map(column => (
                      <TableCell align={'center'} {...column.getHeaderProps()}>
                          <span>{column.canFilter ? column.render('Filter') : null}</span>
                      </TableCell>
                  ))}
                </TableRow>
              }
            </React.Fragment>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
                <StyledTableRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                    return <StyledTableCell align={'center'} {...cell.getCellProps()}>{cell.render("Cell")}</StyledTableCell>;
                    })}
                </StyledTableRow>
            );
          })}
        </TableBody>
        <tfoot>
          <TableRow>
            <TableSwitcher {...fwdProps}/>
          </TableRow>
        </tfoot>
      </Table>
      </TableContainer>
    );

}

export default AdminTable
import React, { useState, useEffect, useMemo, useContext } from "react";

import ControlledDialogForm from "../Utilities/ControlledDialogForm";
import {
  IconButton,
  TextField,
  MenuItem,
  Grid,
  Paper,
  Typography,
  Box,
  Fab,
  Chip,
  makeStyles,
} from "@material-ui/core";

import ClientSelect from "./ClientSelect";

import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

import { useGetRequest, useSendRequest } from "../Utilities/API";

import AdminTable from "../Utilities/AdminTable";
import Loading from "./Status/Loading";
import ErrorView from "./Status/ErrorView";
import moment from "moment";
import { SelectColumnFilter } from "../Utilities/filters";
import UserContext from "../Providers/UserContext";

import { DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  doneChip: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  progressChip: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  cancelChip: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  scheduledChip: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
}));

const noWSp = {
  whiteSpace: "nowrap",
};

const noWSpCell = ({ value }) => {
  return <div style={noWSp}>{value}</div>;
};

const typeCell = ({ row, value }) => {
  if (row.original.type === 1)
    return (
      <div style={noWSp} className="text-primary">
        {value}
      </div>
    );
  else
    return (
      <div style={noWSp} className="text-success">
        {value}
      </div>
    );
};

const infoCell = ({ row }) => {
  if (row.original.scheduledFor && row.original.status === 2) {
    return (
      <div style={noWSp}>
        Scheduled For {moment(row.original.scheduledFor).format("YYYY-MM-DD")}
      </div>
    );
  } else if (row.original.status === 3 && row.original.closedDate) {
    return (
      <div style={noWSp}>
        Completed On {moment(row.original.closedDate).format("YYYY-MM-DD")}
      </div>
    );
  } else if (row.original.status === 9 && row.original.closedDate) {
    return (
      <div style={noWSp}>
        Cancelled On {moment(row.original.closedDate).format("YYYY-MM-DD")}
      </div>
    );
  } else {
    return null;
  }
};

const typeaccess = (row) => {
  let value = row.type;
  switch (value) {
    case 1:
      return "Error Report";
    default:
      return "Feature Request";
  }
};

const statusacess = (row) => {
  let value = row.status;
  switch (value) {
    case 1:
      return "In Progress";
    case 2:
      return "Scheduled";
    case 3:
      return "Completed";
    case 9:
      return "Cancelled";
    default:
      return "Pending";
  }
};

const StatusCell = ({ row, value }) => {
  const classes = useStyles();

  let variant = () => {
    switch (row.original.status) {
      case 1:
        return classes.progressChip;
      case 2:
        return classes.scheduledChip;
      case 3:
        return classes.doneChip;
      case 9:
        return classes.cancelChip;
      default:
        return "";
    }
  };

  return <Chip size={"small"} className={variant()} label={value} />;
};

const priorityaccess = (row) => {
  let value = row.priority;
  switch (value) {
    case 1:
      return "Medium";
    case 2:
      return "High";
    default:
      return "Low";
  }
};

function AddRequest({
  requestInfo,
  show,
  setShow,
  closeModal,
  featureAdded,
  featureModified,
  featureRemoved,
  chosenClient,
}) {
  const [fData, setfData] = useState(null);

  const { authUser } = useContext(UserContext);

  const handleChange = (event) => {
    const { name, value } = event.target;
    var newData = { ...fData };
    newData[name] = value;
    setfData(newData);
  };

  const { sending, sendPostRequest, sendPutRequest } = useSendRequest();

  const submitRequest = (event) => {
    event.preventDefault();
    let sendData = { ...fData, userId: authUser.id };
    if (!requestInfo) {
      sendData.status = 0;
      sendPostRequest(
        `/features/createfeature/${chosenClient}`,
        sendData,
        (e, data) => {
          if (e) return console.log(e);
          let newFeature = { ...data, user: authUser };
          setfData(null);
          featureAdded(newFeature);
          setShow(false);
        }
      );
    } else {
      sendPutRequest(
        `/features/updatefeature/${requestInfo.id}`,
        sendData,
        (e) => {
          if (e) return console.log(e);
          setfData(null);
          let newItem = { ...requestInfo };
          for (var key in sendData) {
            newItem[key] =
              key === "type" || key === "status" || key === "priority"
                ? parseInt(sendData[key])
                : sendData[key];
          }
          featureModified(newItem);
          setShow(false);
        }
      );
    }
  };

  const userfields = [
    {
      type: "select",
      label: "Request Type",
      changeFxn: handleChange,
      name: "type",
      required: true,
      defaultValue: requestInfo ? requestInfo.type || 0 : "",
      options: [
        { value: "", label: "" },
        { value: 0, label: "Feature Request" },
        { value: 1, label: "Error Report" },
      ],
    },
    {
      type: "text",
      label: "Description",
      name: "description",
      required: true,
      changeFxn: handleChange,
      multiline: true,
      defaultValue: requestInfo ? requestInfo.description || "" : "",
    },
    {
      label: "Priority",
      type: "select",
      changeFxn: handleChange,
      name: "priority",
      required: true,
      defaultValue: requestInfo
        ? requestInfo.priority
          ? requestInfo.priority
          : 0
        : "",
      options: [
        { value: "", label: "" },
        { value: 0, label: "Low" },
        { value: 1, label: "Medium" },
        { value: 2, label: "High" },
      ],
    },
  ];

  const developerfields = requestInfo
    ? [
        {
          type: "select",
          name: "status",
          label: "Status",
          changeFxn: handleChange,
          required: true,
          defaultValue: requestInfo.status || 0,
          options: [
            { value: "", label: "" },
            { value: 0, label: "Pending" },
            { value: 1, label: "In Progress" },
            { value: 2, label: "Scheduled" },
            { value: 3, label: "Completed" },
            { value: 9, label: "Cancelled" },
          ],
        },
        {
          type: "text",
          name: "appVersion",
          label: "Application Version",
          changeFxn: handleChange,
          required: false,
          defaultValue: requestInfo.appVersion || "",
        },
        {
          type: "date",
          label: "Scheduled For",
          changeFxn: (date) =>
            handleChange({
              target: { name: "scheduledFor", value: date.format() },
            }),
          required: false,
          value: fData
            ? fData.scheduledFor
              ? fData.scheduledFor
              : requestInfo.scheduledFor || null
            : requestInfo.scheduledFor || null,
        },
        {
          type: "date",
          label: "Closed Date",
          changeFxn: (date) =>
            handleChange({
              target: { name: "closedDate", value: date.format() },
            }),
          required: false,
          value: fData
            ? fData.closedDate
              ? fData.closedDate
              : requestInfo.closedDate || null
            : requestInfo.closedDate || null,
        },
        {
          type: "text",
          name: "devComments",
          label: "Developer Comments",
          changeFxn: handleChange,
          required: false,
          defaultValue: requestInfo.devComments || "",
          multiline: true,
        },
      ]
    : [];

  return (
    <ControlledDialogForm
      exitFxn={() => {
        setfData(null);
        closeModal();
      }}
      show={show}
      setShow={setShow}
      submitRequest={submitRequest}
      dialogTitle={"Feature Request / Error Report"}
      saveReq={sending}
    >
      <Grid container spacing={2}>
        {userfields.map((item, i) => (
          <Grid key={i} item xs={12}>
            <TextField
              fullWidth
              name={item.name}
              variant={"filled"}
              select={item.type === "select"}
              multiline={item.multiline}
              label={item.label}
              required={item.required}
              defaultValue={item.defaultValue}
              onChange={item.changeFxn}
            >
              {item.options &&
                item.options.map((opt, j) => (
                  <MenuItem key={j} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        ))}
        {requestInfo &&
          developerfields.map((item, i) => (
            <Grid item xs={12} key={`d${i}`}>
              {item.type === "date" ? (
                <DatePicker
                  inputVariant={"filled"}
                  fullWidth
                  value={item.value}
                  label={item.label}
                  onChange={item.changeFxn}
                  required={item.required}
                />
              ) : (
                <TextField
                  name={item.name}
                  fullWidth
                  variant={"filled"}
                  select={item.type === "select"}
                  multiline={item.multiline}
                  label={item.label}
                  required={item.required}
                  defaultValue={item.defaultValue}
                  onChange={item.changeFxn}
                >
                  {item.options &&
                    item.options.map((opt, j) => (
                      <MenuItem key={j} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Grid>
          ))}
      </Grid>
    </ControlledDialogForm>
  );
}

function FeatureTable({ featureData, openEdit }) {
  const { authUser } = useContext(UserContext);

  const columns = useMemo(() => {
    let headerArr = [
      {
        Header: "Status",
        accessor: statusacess,
        Filter: SelectColumnFilter,
        filter: "includes",
        Cell: StatusCell,
      },
      {
        Header: "Priority",
        accessor: priorityaccess,
        Filter: SelectColumnFilter,
        filter: "includes",
        Cell: noWSpCell,
      },
      {
        Header: "Type",
        Filter: SelectColumnFilter,
        filter: "includes",
        accessor: typeaccess,
        Cell: typeCell,
      },
      {
        Header: "Requested",
        id: "started",
        accessor: (row) => {
          return moment(row.createdAt).format("YYYY-MM-DD");
        },
        Cell: noWSpCell,
      },
      {
        Header: "Requestor",
        id: "user",
        accessor: (row) => row.user.name,
        Cell: noWSpCell,
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => <div style={{ minWidth: "200px" }}>{value}</div>,
      },
      { Header: "Info", Cell: infoCell },
      {
        Header: "Comments",
        accessor: "devComments",
        Cell: ({ value }) => <div style={{ minWidth: "150px" }}>{value}</div>,
      },
      { Header: "Version", accessor: "appVersion", Cell: noWSpCell },
    ];
    if (authUser && authUser.role > 10)
      headerArr.unshift({
        Header: "",
        id: "edit",
        disableFilters: true,
        disableSortBy: true,
        accessor: (row) => {
          return (
            <IconButton onClick={() => openEdit(row)}>
              <EditIcon />
            </IconButton>
          );
        },
      });
    return headerArr;
  }, [authUser, openEdit]);

  const data = useMemo(() => [...featureData], [featureData]);

  return <AdminTable columns={columns} data={data} />;
}

export default function Features() {
  const [show, setShow] = useState(false);
  const { authUser } = useContext(UserContext);
  const [selFeat, setSelFeat] = useState(null);
  const [chosenClient, setChosenClient] = useState(null);

  const {
    manualUpdate,
    updating,
    updateError,
    timedOut,
    sendRequest,
    fetchData: fList,
  } = useGetRequest({ timeout: 30000 });

  const selectedClient = React.useMemo(() => {
    if (authUser && authUser.clientId) return authUser.clientId;
    if (chosenClient) return chosenClient;
    return null;
  }, [authUser, chosenClient]);

  //called on load to fetch item list
  useEffect(() => {
    if (!selectedClient) return;
    sendRequest(`/features/getfeatures/${selectedClient}`);
  }, [sendRequest, selectedClient]);

  const openEdit = (feature) => {
    setSelFeat(feature);
    setShow(true);
  };

  const closeModal = () => {
    setSelFeat(null);
  };

  const featureAdded = (newFeature) => {
    let newList = [...fList];
    newList.unshift(newFeature);
    manualUpdate(newList);
    closeModal();
  };
  const featureModified = (upFeature) => {
    let newList = [...fList];
    for (var i in newList) {
      if (upFeature.id === newList[i].id) {
        newList.splice(i, 1, upFeature);
        break;
      }
    }
    manualUpdate(newList);
    closeModal();
  };

  const featureRemoved = (removeId) => {
    let newList = [...fList];
    for (var i in newList) {
      if (removeId === newList[i].id) {
        newList.splice(i, 1);
        break;
      }
    }
    manualUpdate(newList);
    closeModal();
  };

  const errorProps = { updateError, timedOut };

  return (
    <>
      {!selectedClient ? (
        <ClientSelect setChosenClient={setChosenClient} />
      ) : (
        <>
          {(updateError || timedOut) && <ErrorView {...errorProps} />}
          {updating && <Loading />}
          {fList && (
            <>
              <Box p={3}>
                <Paper>
                  <Box display={"flex"} alignItems={"center"} p={2} pb={0}>
                    <Box marginRight={"auto"}>
                      <Typography variant={"h5"}>Development Status</Typography>
                    </Box>
                    <Fab
                      size={"small"}
                      color={"secondary"}
                      onClick={() => setShow(true)}
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                  {!fList ? (
                    <Loading loadCol="dark" />
                  ) : (
                    <Box p={2}>
                      <Paper variant={"outlined"}>
                        <FeatureTable featureData={fList} openEdit={openEdit} />
                      </Paper>
                    </Box>
                  )}
                </Paper>
              </Box>
              <AddRequest
                requestInfo={selFeat}
                show={show}
                setShow={setShow}
                closeModal={closeModal}
                featureAdded={featureAdded}
                featureModified={featureModified}
                featureRemoved={featureRemoved}
                chosenClient={selectedClient}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

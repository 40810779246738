import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#26495c",
    },
    secondary: {
      //main: '#45818e',
      main: "#c66b3d",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "rgb(205,214,215)",
      //default: "#6D757D",
    },
  },
});

export default theme;

import React from "react";

import ListController from "../Utilities/ListController";
import CompanyDialog from "./Dialogs/CompanyDialog";

import {
  Fade,
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

function Item({ item, handleItemClick }) {
  return (
    <ListItem divider button onClick={() => handleItemClick(item)}>
      <ListItemText primary={item.name} />
    </ListItem>
  );
}

function CompanyList({ itemList, handleItemClick }) {
  return (
    <Fade in={true}>
      <Container disableGutters maxWidth={"sm"} component={Paper}>
        <List>
          <ListItem divider>
            <ListItemText
              primary={"Clients:"}
              primaryTypographyProps={{ color: "primary", variant: "h6" }}
            />
            <ListItemSecondaryAction>
              <IconButton color={"secondary"} onClick={() => handleItemClick()}>
                <AddCircleIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          {itemList.map((item, i) => (
            <Item key={i} item={item} handleItemClick={handleItemClick} />
          ))}
        </List>
      </Container>
    </Fade>
  );
}

export default function Companies() {
  const listProps = {
    fetchEndpoint: "/clients/clientlist",
    updateEndpoint: "/clients/updateclient",
    createEndpoint: "/clients/newclient",
    TableView: CompanyList,
    DialogView: CompanyDialog,
  };

  return <ListController {...listProps} />;
}

import React, { useState, useEffect } from "react";
import { useGetRequest } from "../../Utilities/API";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
} from "@material-ui/core";
import ErrorView from "../Status/ErrorView";
import Loading from "../Status/Loading";

const userRoles = [
  { value: 10, name: "Client" },
  { value: 20, name: "Developer" },
  { value: 30, name: "Administrator" },
];

export default function ItemDialog({
  selectedItem,
  setSelectedItem,
  updateItemRequest,
  createItemRequest,
  showDialog,
  setShowDialog,
  sending,
}) {
  const [formData, setFormData] = useState({});

  const {
    updating,
    updateError,
    timedOut,
    sendRequest,
    fetchData: companyList,
  } = useGetRequest({ timeout: 30000 });
  const errorProps = { updateError, timedOut };

  //called on load to fetch item list
  useEffect(() => {
    sendRequest(`/clients/clientlist`);
  }, [sendRequest]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    let newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (selectedItem) return updateItemRequest(selectedItem.id, formData);
    createItemRequest(formData);
  };

  return (
    <>
      {(updateError || timedOut) && <ErrorView {...errorProps} />}
      {updating && <Loading />}
      {companyList && (
        <Dialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          onExited={() => setSelectedItem(null)}
          fullWidth
          maxWidth={"sm"}
        >
          <DialogContent>
            <form id={"item-form"} onSubmit={handleFormSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label={"Name"}
                    name={"name"}
                    required
                    defaultValue={selectedItem ? selectedItem.name : ""}
                    onChange={handleFormChange}
                    fullWidth
                    variant={"filled"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={"Email Address"}
                    name={"email"}
                    type={"email"}
                    required
                    disabled={Boolean(selectedItem)}
                    defaultValue={selectedItem ? selectedItem.email : ""}
                    onChange={handleFormChange}
                    fullWidth
                    variant={"filled"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={"Password"}
                    name={"password"}
                    type={"password"}
                    required
                    defaultValue={selectedItem ? selectedItem.password : ""}
                    onChange={handleFormChange}
                    fullWidth
                    variant={"filled"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={"Role"}
                    name={"role"}
                    select
                    required
                    defaultValue={selectedItem ? selectedItem.role : ""}
                    onChange={handleFormChange}
                    fullWidth
                    variant={"filled"}
                  >
                    <MenuItem value={""}></MenuItem>
                    {userRoles.map((role) => (
                      <MenuItem value={role.value} key={role.value}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={"Client"}
                    name={"clientId"}
                    select
                    defaultValue={selectedItem ? selectedItem.clientId : ""}
                    onChange={handleFormChange}
                    fullWidth
                    variant={"filled"}
                  >
                    <MenuItem value={""}></MenuItem>
                    {companyList.map((client) => (
                      <MenuItem value={client.id} key={client.id}>
                        {client.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button disabled={sending} onClick={() => setShowDialog(false)}>
              Cancel
            </Button>
            <Button
              color={"secondary"}
              disabled={sending}
              type={"submit"}
              form={"item-form"}
              variant={"contained"}
            >
              {sending ? "Saving..." : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

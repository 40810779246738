import React, { useState, useContext } from "react";
import UserContext from "../Providers/UserContext";

import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Button,
  Card,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
} from "@material-ui/core";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useSendRequest } from "../Utilities/API";
import Toast from "../Utilities/Toast";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    width: "100%",
    //backgroundImage: `url(${waterfallImg})`,
    //backgroundRepeat: "no-repeat",
    //backgroundSize: "cover",
  },
  loginHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  loginContent: {
    minHeight: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default function Login() {
  const classes = useStyles();
  const { setAuthUser } = useContext(UserContext);
  const [error, setError] = useState(false);
  const [fdata, setFdata] = useState(null);
  const { sendPostRequest } = useSendRequest();

  const handleChange = (event) => {
    const { name, value } = event.target;
    var newData = { ...fdata };
    newData[name] = value;
    setFdata(newData);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    sendPostRequest(`/auth/validateuser`, fdata, (e, data) => {
      if (e) {
        setError(e.response.data);
      }
      setAuthUser(data);
    });
  };

  const toastProps = { error, setError };

  return (
    <>
      <Box
        className={classes.root}
        p={2}
        display={"flex"}
        alignItems={"center"}
      >
        <Container maxWidth={"xs"}>
          <Card>
            <CardHeader
              className={classes.loginHeader}
              title={"Demo Program Access"}
            />
            <CardContent className={classes.loginContent}>
              <form id={"loginform"} onSubmit={handleLogin}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={"Email"}
                      name={"email"}
                      variant={"filled"}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={"Password"}
                      name={"password"}
                      variant={"filled"}
                      type={"password"}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </form>
            </CardContent>
            <CardActions>
              <Box pb={1} width={"100%"}>
                <Button
                  type={"submit"}
                  form={"loginform"}
                  endIcon={<KeyboardArrowRight />}
                  fullWidth
                  variant={"contained"}
                  color={"primary"}
                >
                  Continue
                </Button>
              </Box>
            </CardActions>
          </Card>
        </Container>
      </Box>
      <Toast {...toastProps} />
    </>
  );
}
